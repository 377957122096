import React from 'react';
import './App.css';
import RedeemForm from './RedeemForm';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://production';

interface ServerData {
  result: string;
  name: string;
  id: number;
  token1: string;
  token2: string;
};

interface AppState {
  serverData: ServerData | null;
  validError: string | null;
  serverError: string | null;
  isBusy: boolean;
};

class App extends React.Component<any, AppState> {


  constructor(props: any) {
    super(props);
    this.state = {
      serverData: null,
      validError: null,
      serverError: null,
      isBusy: false
    };
  }

  async componentDidMount() {
    this.setState({
      
    });
  }

  async redeem(email: string, name: string, password: string, code: string) {
    this.setState({
      serverError: null,
      validError: null,
      isBusy: true
    });

    const form = new FormData();
    form.append("Name", name);
    form.append("Password", password);
    form.append("Email", email);
    form.append("RegMethod", "regcode");
    form.append("Receipt", code);

    const resp = await fetch(API_ENDPOINT + "/NewAccount/RegisterUser", {
      method: "POST",
      body: form,
    });

    console.log(resp);

    const text = await resp.clone().text();

    resp.json()
      .then(data => {
        console.log(data);
        this.setState({
          serverData: data,
          isBusy: false
        });
      })
      .catch(e => {
        this.setState({
          serverError: text.startsWith("EX") ? text.slice("EX".length) : text,
          isBusy: false
        });
      });
  }
    
  render() {
    const errorText = this.state.validError
      ? this.state.validError
      : this.state.serverError
      ? this.state.serverError
      : null;

    const message = this.state.serverData
      ? <div className="success">
          <p>Your new Star Realms account has been created.</p>
          <p>Please download the app using the links below and login to claim the full version!</p>
          <div className="links_container">
            <a className="banner" href="https://store.steampowered.com/app/438140/">
              <img alt="Get it on Steam" src="/banner_steam.png"/>
            </a>
            <a className="banner" href="https://play.google.com/store/apps/details?id=com.starrealms.starrealmsapp&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img alt="Get it on Google Play" src="/banner_android.png"/>
            </a>
            <a className="banner" href="https://itunes.apple.com/us/app/star-realms/id893447125?mt=8/">
              <img alt="Download on the App Store" src="/banner_ios.png"/>
            </a>
            <a className="banner" href="https://apps.apple.com/us/app/star-realms/id1066107760?ls=1&mt=12">
              <img alt="Download on the Mac App Store" src="/banner_mac.svg"/>
            </a>
          </div>
          
        </div>
      : null;

    const form = this.state.serverData ? null : (
      <RedeemForm
        errorText={errorText}
        onValidError={e => this.setState({validError: e})}
        submitCallback={(e,n,p,c) => this.redeem(e,n,p,c)}
        disableRegistration={this.state.isBusy}/>
    );

    return (
      <div className="container">
         
          <header>
            <div className="row">
              <div>
                <img src="/logo.png"/>
              </div>
              <div className="col align-self-center">
                <h1>Code Activation</h1>
                <p>Redeem your free Star Realms account</p>
				<ul>
					<li>Create your account on this Redeem page</li>
					<li><a href="https://www.starrealms.com/digital-game/" target="_blank">Download the free version of Star Realms</a></li>
					<li>Open the app and click "Play Online" to log in with the account you created</li>
					<li>Have fun!</li>
				</ul>
              </div>
            </div>
          </header>

          <main>
            { message }
            { form }
          </main>

      </div>
    );  
  }
}

export default App;
