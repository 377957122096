import React from 'react';

interface ReedemFormProps {
  submitCallback: (email: string, name: string, password: string, code: string) => void;
  onValidError: (error: string) => void;
  errorText: string | null;
  disableRegistration: boolean;
};

const RedeemForm: React.FC<ReedemFormProps> = (props) => {
    let emailRef = React.createRef<HTMLInputElement>();
    let nameRef = React.createRef<HTMLInputElement>();
    let passwordRef = React.createRef<HTMLInputElement>();
    let repeatPasswordRef = React.createRef<HTMLInputElement>();
    let codeRef = React.createRef<HTMLInputElement>();
    let formRef = React.createRef<HTMLFormElement>();

    const error = props.errorText
      ? <div className="failure">{props.errorText}</div>
      : null;

    return (
      <div className="form_container">
  
        <h3>Registration</h3>
      
        {error}

        <form ref={formRef}>

          <div className="form-group row">
            <label
              className="col align-self-center"
              htmlFor="red_code_input">Registration code</label>
            <input className="form-control col-9" ref={codeRef} type="text"
                id="red_code_input"
                placeholder="code"
                required/>
          </div>

          <h3>Create new Star Realms account</h3>

          <div className="form-group row">
            <label
            className="col align-self-center"
              htmlFor="email_input">Email</label>
            <input className="form-control col-9" ref={emailRef} type="email"
              id="email_input"
              placeholder="email"
              required/>
          </div>
          
          <div className="form-group row">
            <label
              className="col align-self-center"
              htmlFor="username_input">Username</label>         
            <input className="form-control col-9" ref={nameRef} type="text"
              id="username_input"
              placeholder="username (3-12 characters)"
              required
              pattern="^[A-Za-z][A-Za-z0-9]{2,11}"/>
          </div>
  
          <div className="form-group row">
            <label
              className="col align-self-center"
              htmlFor="password_input">Password</label>
            <input className="form-control col-9" ref={passwordRef} type="password"
                id="password_input"
                placeholder="password"
                required/>
          </div>

          <div className="form-group row">
            <label
              className="col align-self-center"
              htmlFor="repeat_password_input">Repeat password</label>
            <input className="form-control col-9" ref={repeatPasswordRef} type="password"
                id="repeat_password_input"
                placeholder="password"
                required/>
          </div>
  
          <button 
            type="button" className="btn btn-primary"
            disabled={props.disableRegistration}
            onClick={e => {
                if (formRef.current?.reportValidity()) {
                  if (repeatPasswordRef.current!.value !== passwordRef.current!.value) {
                    props.onValidError("Passwords don't match!");
                    return;
                  }
                  props.submitCallback(
                    emailRef.current!.value,
                    nameRef.current!.value,
                    passwordRef.current!.value,
                    codeRef.current!.value);
                }
              }}>Register</button>        

        </form>
  
      </div>);
  }

export default RedeemForm;
